import {Component, OnInit} from '@angular/core';
import {AppComponent} from './app.component';
import {AppMainComponent} from './app.main.component';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[];

    constructor(public app: AppComponent, public appMain: AppMainComponent) {}

    ngOnInit() {
        this.model = [
            {
                label: 'Yoga', icon: 'pi pi-fw pi-home',
                items: [
                    {label: 'Home', icon: 'pi pi-fw pi-home', routerLink: ['/']},
                    {label: 'Über mich', icon: 'pi pi-fw pi-user', routerLink: ['/jessica']},
                    {label: 'Yoga', icon: 'pi pi-fw pi-book', routerLink: ['/yoga']},
                    {label: 'Praxistipps', icon: 'pi pi-fw pi-pencil', routerLink: ['/tips']},
                    {label: 'Preise', icon: 'pi pi-fw pi-money-bill', routerLink: ['/prices']},
                    {label: 'Ort', icon: 'pi pi-fw pi-map-marker', routerLink: ['/place-wawi']},
                    // {label: 'Ort Aula', icon: 'pi pi-fw pi-map-marker', routerLink: ['/place-aula']},
                    // {label: 'Ort Raum des Seins', icon: 'pi pi-fw pi-map-marker', routerLink: ['/place-raumdesseins']},
                    {label: 'Kalender', icon: 'pi pi-fw pi-calendar-times', routerLink: ['/calendar']},
                    {label: 'Kontakt', icon: 'pi pi-fw pi-id-card', routerLink: ['/contact']},
                    // {label: 'Bilder', icon: 'pi pi-fw pi-image', routerLink: ['/gallery']}
                ]
            }
        ];
    }
}
