<div class="p-grid dashboard p-d-flex p-jc-center">

    <div class="p-col-12 p-md-12 p-lg-6 card yoga-dash p-mr-3 p-mb-3">
        <div class="p-grid">
            <div class="p-col-12 p-lg-6 p-d-flex p-jc-center p-ai-center">
                <img src="assets/yoga/images/dash-logo.png" alt="Logo">
            </div>
            <div class="p-col-12 p-lg-6 p-d-flex p-jc-center p-ai-center">
                <div class="yoga-text no-wrap">
                    <h4>Yoga Lichtquelle</h4>
                    <!-- <p>Tösstalstrasse 14, 8360 Eschlikon</p> -->
                    <h5>Gruppenunterricht:</h5>
                    <table>
                        <tbody>
                            <tr>
                                <td>Dienstag:</td>
                                <td>18:45 bis 20:00 Uhr</td>
                            </tr>
                            <tr>
                                <td>Mittwoch:</td>
                                <td>09:45 bis 11:00 Uhr</td>
                            </tr>
                            <tr>
                                <td>Donnerstag:</td>
                                <td>18:30 bis 19:45 Uhr</td>
                            </tr>
                        </tbody>
                    </table>
                    <button pButton type="button" label="KALENDER" class="p-button-raised"
                        [routerLink]="['/calendar']"></button>
                </div>
            </div>
        </div>
    </div>

    <div class="p-col-12 p-md-12 p-lg-6 card yoga-dash p-mr-3 p-mb-3">
        <div class="p-grid">
            <div class="p-col-12 p-lg-6 p-d-flex p-jc-center p-ai-center">
                <img src="assets/yoga/images/dash-jessica.jpg" alt="Jessica Meuli">
            </div>
            <div class="p-col-12 p-lg-6 p-d-flex p-jc-center p-ai-center">
                <div class="yoga-text no-wrap">
                    <h4>Jessica Meuli (-Häderli)</h4>
                    <p>Sonnenhaldenstrasse 5, 8360 Wallenwil</p>
                    <h6>Diplomierte Yogalehrerin (BYV)</h6>
                    <table>
                        <tbody>
                            <tr>
                                <td>E-Mail:</td>
                                <td><a href="mailto:jessica.meuli@gmx.ch">jessica.meuli@gmx.ch</a></td>
                            </tr>
                            <tr>
                                <td>Tel.: </td>
                                <td><a href="tel:+41715511529">+41 71 551 15 29</a></td>
                            </tr>
                            <tr>
                                <td>Natel: </td>
                                <td><a href="tel:+41797712134">+41 79 771 21 34</a></td>
                            </tr>
                        </tbody>
                    </table>
                    <button pButton type="button" label="MEHR ÜBER MICH" class="p-button-raised"
                        [routerLink]="['/jessica']"></button>
                </div>
            </div>
        </div>
    </div>

    <div class="p-col-12 p-md-12 p-lg-6 card yoga-dash p-mr-3 p-mb-3">
        <div class="p-grid">
            <div class="p-col-12 p-lg-6 p-d-flex p-jc-center p-ai-center">
                <img src="assets/yoga/images/doterra.jpg" alt="Logo">
            </div>
            <div class="p-col-12 p-lg-6 p-d-flex p-jc-center p-ai-center">
                <div class="yoga-text yoga-text-center wrap">
                    <h4>Ätherische Öle</h4>
                    <p>
                        Ätherische Öle von doTERRA.
                        <br>Extrakte aus Pflanzen mit erstaunlichen Vorteilen. Natürlich und einfach in der Anwendung.
                    </p>
                    <a href="https://shop.yoga-lichtquelle.ch" target="_blank" rel="noopener">
                        <button pButton type="button" label="Shop" class="p-button-raised"></button>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="p-col-12 p-md-12 p-lg-6 card yoga-dash p-mr-3 p-mb-3">
        <div class="p-grid">
            <div class="p-col-12 p-lg-6 p-d-flex p-jc-center p-ai-center">
                <img src="assets/yoga/images/wawi-495w.jpg" alt="Logo">
            </div>
            <div class="p-col-12 p-lg-6 p-d-flex p-jc-center p-ai-center">
                <div class="yoga-text yoga-text-center wrap">
                    <h4>Ort</h4>
                    <p>Der Raum befindet sich an der <br>Tösstalstrasse 14, 8360 Eschlikon (Wawi), <br>im Trainingsraum vom
                        Thai-Shark Gym.</p>
                    <button pButton type="button" label="WEGBESCHREIBUNG" class="p-button-raised"
                        [routerLink]="['/place-wawi']">
                    </button>
                    <a href="https://goo.gl/maps/UxdPtonS9TDuH6KH6" target="_blank" rel="noopener">
                        <button pButton type="button" label="ADRESSE IN GOOGLE MAPS ÖFFNEN" class="p-button-raised"></button>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="p-col-12 p-md-12 p-lg-6 card yoga-dash p-mr-3 p-mb-3">
        <div class="p-grid">
            <div class="p-col-12 p-lg-6 p-d-flex p-jc-center p-ai-center">
                <img src="assets/yoga/images/zoom.jpg" alt="Zoom">
            </div>
            <div class="p-col-12 p-lg-6 p-d-flex p-jc-center p-ai-center">
                <div class="yoga-text yoga-text-center wrap">
                    <h4>Ab 2022:</h4>
                    <h5>Yoga über Zoom.</h5>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="p-col-12 p-md-12 p-lg-6 card yoga-dash p-mr-3 p-mb-3">
        <div class="p-grid">
            <div class="p-col-12 p-lg-6 p-d-flex p-jc-center p-ai-center">
                <img src="assets/yoga/images/dash-quote.jpg" alt="Sonne">
            </div>
            <div class="p-col-12 p-lg-6 p-d-flex p-jc-center p-ai-center">
                <div>
                    <blockquote>
                        Der Körper ist dein Tempel. Halte ihn sauber und rein, damit die Seele darin wohnen kann!
                    </blockquote>
                    <p>
                        Yoga ist der Weg um dorthin zu gehen woher wir kommen. Egal welche Nationalität und Religion.
                    </p>
                </div>
            </div>
        </div>
    </div> -->

</div>
