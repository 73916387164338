<div class="topbar">
    <div class="topbar-wrapper">
        <div class="topbar-left">
            <a routerLink="/" class="topbar-logo">
                <img
                    [src]="app.layout === 'default' ? 'assets/layout/images/logo-black.png' : 'assets/layout/images/logo-white.png'"
                    style="border: 0 none">
            </a>

            <a href="#" id="menu-button" (click)="appMain.onMenuButtonClick($event)">
                <i class="pi pi-bars"></i>
            </a>

        </div>

    </div>
</div>
