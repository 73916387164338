import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {AppMainComponent} from './app.main.component';

import { DashboardComponent } from './dashboard/dashboard.component';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppMainComponent,
                children: [
                    {path: '', component: DashboardComponent},
                    {
                        path: '',
                        loadChildren: () => import('./yoga/yoga.module').then(m => m.YogaModule),
                        data: { preload: true }
                    },
                    {
                        path: '',
                        loadChildren: () => import('./place/place.module').then(m => m.PlaceModule)
                    },
                    {
                        path: '',
                        loadChildren: () => import('./calendar/calendar.module').then(m => m.YLCalendarModule)
                    }
                ]
            }
        ], {scrollPositionRestoration: 'enabled'})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
